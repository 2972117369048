<template>
  <el-dialog
    v-model="showCreateJournalDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-transactions-create-journal-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-transactions-create-journal-dialog-header__title">
        {{ props.model === 'add' ? t('title.createJournal') : t('button.recategorize') }}
      </h4>
    </template>
    <div class="elv-transactions-create-journal-content">
      <JournalTypeOverlayDropdown
        v-model="journalTypeId"
        :showGeneralJournal="!props.isBatch"
        :dropDownData="props.journalTypeList"
        @onChangeJournalTypeSelect="() => {}"
        @onCreateGeneralJournal="onCreateGeneralJournal"
      />
    </div>
    <template #footer>
      <elv-button
        height="44"
        width="112"
        round
        type="primary"
        :loading="createLoading"
        :disabled="!journalTypeId"
        @click="onTaggedJournalCreation"
        >{{ props.model === 'add' ? t('button.create') : t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>

  <AddJournalDialog
    ref="addJournalDialogRef"
    :model="props.model"
    tableType="transactions"
    :currentData="props.currentData"
    :isRecategorize="isRecategorize"
    :journalTypeList="props.journalTypeList"
    :selectedJournalTypeId="journalTypeId"
    @resetList="emit('onResetList')"
  />
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import AddJournalDialog from '../Journal/AddJournalDialog.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import JournalTypeOverlayDropdown from '@/pages/Financials/Project/components/JournalTypeOverlayDropdown.vue'
import LedgerApi from '@/api/LedgerApi'

const props = defineProps({
  currentData: {
    type: Object,
    required: true
  },
  journalTypeList: {
    type: Array,
    default: () => {
      return []
    }
  },
  isBatch: {
    type: Boolean,
    default: false
  },
  multipleSelection: {
    type: Array,
    default: () => {
      return []
    }
  },
  model: {
    type: String,
    default: 'add' // add, edit
  }
})

const route = useRoute()
const { t } = useI18n()
const emit = defineEmits(['onResetList'])

const journalTypeId = ref('')
const isRecategorize = ref(false)
const showCreateJournalDialog = ref(false)
const createLoading = ref(false)
const addJournalDialogRef = useTemplateRef('addJournalDialogRef')
const transactionStore = useTransactionStore()

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentJournalType = computed(() => {
  let data: any = {}
  data = find(transactionStore.journalTypeList, { journalTypeId: journalTypeId.value })
  return data
})

const onCheckCreateJournalDialog = () => {
  showCreateJournalDialog.value = !showCreateJournalDialog.value
}

const onCreateGeneralJournal = (id: string) => {
  journalTypeId.value = id
  addJournalDialogRef.value?.onCheckAddJournalDialog()
  onCheckCreateJournalDialog()
}

const onCreateSpecialJournal = async () => {
  try {
    const params: any = {
      journalTypeId: journalTypeId.value
    }
    if (props.isBatch) {
      params.transactionIdPairs = props.multipleSelection.map((item: any) => {
        return {
          transactionIds: [item.transactionId]
        }
      })
    } else {
      params.transactionIdPairs = [{ transactionIds: [props.currentData.transactionId] }]
    }
    const { data } = await LedgerApi.createJournal(entityId.value, params)
    if (data.length > 0) {
      ElMessage.success(t('message.createJournalSuccess'))
      emit('onResetList')
    } else {
      ElMessage.error(t('message.createJournalFailed'))
    }
    onCheckCreateJournalDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    createLoading.value = false
  }
}

const onEditSpecialJournal = async () => {
  try {
    const params: any = {
      journalTypeId: journalTypeId.value
    }
    await LedgerApi.editJournalActivity(entityId.value, props.currentData.journal?.journalActivityId, params)
    ElMessage.success(t('message.success'))
    emit('onResetList')
    onCheckCreateJournalDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    createLoading.value = false
  }
}

// 创建标记记账
const onTaggedJournalCreation = () => {
  if (currentJournalType.value?.entryType === 'COMPOUND') {
    if (props.currentData?.journalTypeId !== journalTypeId.value) {
      isRecategorize.value = true
    } else {
      isRecategorize.value = false
    }
    addJournalDialogRef.value?.onCheckAddJournalDialog()
    onCheckCreateJournalDialog()
  } else {
    createLoading.value = true
    if (props.model === 'add') {
      onCreateSpecialJournal()
    } else {
      onEditSpecialJournal()
    }
  }
}

const onCloseDialog = () => {}

defineExpose({ onCheckCreateJournalDialog })

watch(
  [() => showCreateJournalDialog.value, () => props.model],
  () => {
    if (showCreateJournalDialog.value) {
      journalTypeId.value = ''
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-transactions-create-journal-dialog {
  width: 700px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-transactions-create-journal-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 25px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-transactions-create-journal-content {
      border-radius: 4px;
      border: 1px solid #e4e7eb;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
